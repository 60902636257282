import { config } from "@config/config";

interface metaDataType {
    metaTitle: string;
    metaDescription: string;
    fbAppId?: string;
    image: string;
    keywords: string;
    siteName: string;
    type: string;
    imageWidth: number;
    imageHeight: number;
}

export const defaultMetaTagData: metaDataType = {
    metaTitle: "helmet.default.title",
    metaDescription: "helmet.default.description",
    fbAppId: config.FACEBOOK_APP_ID,
    image: "/og-images/default.png",
    keywords: "",
    siteName: "UX Fans",
    type: "website",
    imageWidth: 1200,
    imageHeight: 630,
};
