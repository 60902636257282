import { memo } from "react";
import { Analytics } from "./analytics";
import { Favicon } from "./favicon";
import { MetaTagData, MetaTagDataProps } from "./meta-tag-data";
import { Prefetch } from "./prefetch";

interface Props {
    canonicalUrl?: string;
    noIndex?: boolean;
    metaTagData?: Partial<Omit<MetaTagDataProps, "canonicalUrl" | "replaceFullTitle" | "title">>;
    replaceFullTitle?: boolean;
    title: string;
}

export const Helmet = memo<Props>(({ canonicalUrl, metaTagData = {}, noIndex, replaceFullTitle, title }) => {
    const { metaRobots, ...restMetaTagData } = metaTagData;

    return (
        <>
            <Prefetch />
            <MetaTagData
                canonicalUrl={canonicalUrl}
                metaRobots={noIndex ? "noindex, nofollow" : metaRobots}
                replaceFullTitle={replaceFullTitle}
                title={title}
                {...restMetaTagData}
            />
            <Analytics />
            <Favicon />
        </>
    );
});

Helmet.displayName = "Helmet";
