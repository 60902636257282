import { useLocale } from "@translations/locale-context";
import { Logo } from "@ui/components/logo/logo";
import { TextLink } from "@ui/components/text-link/text-link";
import { cx } from "@uxf/core/utils/cx";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { FC, memo, PropsWithChildren } from "react";

interface SitemapBlockProps {
    title: string;
}

const SitemapBlock: FC<PropsWithChildren<SitemapBlockProps>> = ({ children, title }) => {
    return (
        <div className="flex flex-col gap-y-6">
            <h2 className="ui-typo-overline-large w-fit opacity-40">{title}</h2>
            <div className="space-y-6 text-left [&_>_*]:block [&_>_*]:w-fit">{children}</div>
        </div>
    );
};

interface FooterProps {
    variant?: "white" | "violet";
}

export const Footer = memo<FooterProps>(({ variant = "white" }) => {
    const locale = useLocale();
    const { t } = useTranslation();

    const isEn = locale === "en";
    const linkVariant = variant === "white" ? "violet" : "white";

    return (
        <footer
            className={cx("flex-auto px-6 pb-12 pt-20", variant === "white" ? "bg-white" : "bg-uxfViolet text-white")}
        >
            <div
                className={cx(
                    "ui-typo-body container space-y-20 divide-y divide-opacity-20",
                    variant === "white" ? "divide-uxfViolet" : "divide-white",
                )}
            >
                <div className="flex flex-col-reverse justify-between lg:flex-row">
                    <div className="mt-16 flex flex-col items-start space-y-10 lg:mt-0">
                        <Logo isVertical variant={variant === "white" ? "colorViolet" : "colorWhite"} />
                        <div className="space-y-1">
                            <p>{t("common.slogan")}</p>
                            <p className="text-uxfOrange">#jakzanároďák</p>
                        </div>
                    </div>
                    <div className="md: grid grid-cols-1 gap-y-16 text-center sm:grid-cols-2 md:gap-x-24 lg:grid-cols-3 lg:gap-x-12 xl:gap-x-24">
                        <SitemapBlock title={t("footer.aboutUs")}>
                            <Link href={isEn ? "/en/reference" : "/reference"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.reference")}</TextLink>
                            </Link>
                            <Link href={isEn ? "/en/blog" : "/blog"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.blog")}</TextLink>
                            </Link>
                            <Link href={isEn ? "/en/our-team" : "/nas-tym"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.team")}</TextLink>
                            </Link>
                            <Link href={isEn ? "/en/hiring" : "/kariera"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.workWithUs")}</TextLink>
                            </Link>
                            <Link href={isEn ? "/en/technologies" : "/technologie"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.technologies")}</TextLink>
                            </Link>
                            <Link href={isEn ? "/en/faq" : "/caste-otazky"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.faq")}</TextLink>
                            </Link>
                            <Link
                                href={isEn ? "/en/privacy-policy" : "/ochrana-osobnich-udaju"}
                                passHref
                                legacyBehavior
                            >
                                <TextLink variant={linkVariant}>{t("footer.privacyPolicy")}</TextLink>
                            </Link>
                        </SitemapBlock>
                        <SitemapBlock title={t("footer.ourWork")}>
                            <Link
                                href={isEn ? "/en/product-and-marketing" : "/produkt-a-marketing"}
                                passHref
                                legacyBehavior
                            >
                                <TextLink variant={linkVariant}>{t("footer.productAndMarketing")}</TextLink>
                            </Link>
                            <Link href={isEn ? "/en/cto-as-a-service" : "/it-poradenstvi"} passHref legacyBehavior>
                                <TextLink className="whitespace-nowrap" variant={linkVariant}>
                                    {t("footer.counselling")}
                                </TextLink>
                            </Link>
                            <Link href={isEn ? "/en/ux-design" : "/ux-design"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.UXDesign")}</TextLink>
                            </Link>
                            <Link href={isEn ? "/en/development" : "/vyvoj"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.development")}</TextLink>
                            </Link>
                            <Link
                                href={isEn ? "/en/services/customer-zone" : "/sluzby/klientska-zona"}
                                passHref
                                legacyBehavior
                            >
                                <TextLink variant={linkVariant}>{t("footer.customerZone")}</TextLink>
                            </Link>
                            <Link
                                href={isEn ? "/en/services/web-applications" : "/sluzby/webove-aplikace"}
                                passHref
                                legacyBehavior
                            >
                                <TextLink variant={linkVariant}>{t("footer.webApps")}</TextLink>
                            </Link>
                            <Link
                                href={isEn ? "/en/services/technical-seo" : "/sluzby/technicke-seo"}
                                passHref
                                legacyBehavior
                            >
                                <TextLink variant={linkVariant}>{t("footer.technicalSeo")}</TextLink>
                            </Link>
                        </SitemapBlock>
                        <SitemapBlock title={t("footer.connectWithUs")}>
                            <Link href={isEn ? "/en/contact" : "/kontakt"} passHref legacyBehavior>
                                <TextLink variant={linkVariant}>{t("footer.contact")}</TextLink>
                            </Link>
                            <TextLink
                                href="https://www.linkedin.com/company/ux-fans-s-r-o/mycompany/"
                                target="_blank"
                                variant={linkVariant}
                            >
                                LinkedIn
                            </TextLink>
                            <TextLink
                                href="https://instagram.com/ux_fans?igshid=YmMyMTA2M2Y="
                                target="_blank"
                                variant={linkVariant}
                            >
                                Instagram
                            </TextLink>
                            <TextLink href="https://www.facebook.com/uxfans" target="_blank" variant={linkVariant}>
                                Facebook
                            </TextLink>
                            <TextLink
                                href="https://clutch.co/profile/ux-fans#highlights"
                                target="_blank"
                                variant={linkVariant}
                            >
                                Clutch
                            </TextLink>
                        </SitemapBlock>
                    </div>
                </div>
                <p className="pt-10 text-center">© {new Date().getFullYear()} UX Fans s.r.o.</p>
            </div>
        </footer>
    );
});

Footer.displayName = "Footer";
